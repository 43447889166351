<template>
  <div class="cnt-card" :class="{ actionable: actionable }">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "BaseCard",
  props: {
    actionable: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style scoped>
.cnt-card {
  font-size: 1rem;
  background: #fff;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.16);
  padding: 2.4rem;
  border-radius: 4px;
}

.cnt-card.actionable {
  cursor: pointer;
  padding: 2rem;
  padding-bottom: 1.4rem;
  position: relative;
}

.cnt-card.actionable:active {
  background: var(--c-concrete);
}
</style>
