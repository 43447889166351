<template>
  <div class="img-pic">
    <label class="select-label">
      {{ label }} ({{ reqWidth }}x{{ reqHeight }}):
    </label>
    <div></div>
    <input
      type="file"
      accept=".png, .jpg, .jpeg, .mp4"
      ref="file"
      @change="onFileChange"
      hidden
    />
    <div class="ratio-container" @click="handleResourceAction">
      <img
        :src="url"
        :alt="label"
        class="image ratio-fill"
        ref="ipreview"
        @load="resourceLoaded(1)"
        v-show="url && type == 1"
      />
      <video
        :src="url"
        :autoplay="value != undefined"
        :loop="value != undefined"
        :controls="value === undefined && !error"
        controlslist="nodownload nofullscreen noremoteplayback"
        muted="true"
        class="video ratio-fill"
        ref="vpreview"
        @loadeddata="resourceLoaded(2)"
        v-show="url && type == 2"
      />
      <div class="add-block ratio-fill" v-show="!url">
        <div class="add-cnt">
          <img src="@/assets//actions/add-icon.svg" :alt="hint" class="icon" />
          <h2 class="select-hint">{{ hint }}</h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import ImageManager from "@/controllers/media-manager";
import PopUpMessage, { NotificationType } from "@/models/popup";
import { SelectionState, SelectionType } from "@/models/common";

export default defineComponent({
  name: "ImageSelect",
  props: {
    modelValue: {
      type: File,
    },
    label: {
      type: String,
      required: true,
    },
    hint: {
      type: String,
      required: true,
    },
    reqHeight: {
      type: Number,
      required: true,
    },
    reqWidth: {
      type: Number,
      required: true,
    },
    prevUrl: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      url: this.prevUrl,
      type: this.prevUrl.includes(".mp4")
        ? SelectionType.Video
        : SelectionType.Image,
      error: false,
    };
  },
  emits: ["update:modelValue"],
  methods: {
    handleResourceAction() {
      const input = this.$refs.file as HTMLInputElement;
      input.click();
    },
    onFileChange(event: Event) {
      this.error = false;
      const input = event.target as HTMLInputElement;
      const state = ImageManager.checkFile(input);
      const selection = ImageManager.getFile(input.files, state);
      if (
        state &&
        state != SelectionState.Image &&
        state != SelectionState.Video
      ) {
        var popUp = new PopUpMessage({
          title: "Error con el archivo",
          message: ImageManager.getMediaErrorMessage(state),
          type: NotificationType.Error,
        });
        popUp.show();
        input.value = input.defaultValue;
        this.error = true;
      }
      if (state === SelectionState.Image) {
        this.type = SelectionType.Image;
      } else {
        this.type = SelectionType.Video;
      }
      this.url = selection.url;
      this.value = selection.file;
    },
    resourceLoaded(type: SelectionType) {
      let height = 0;
      let width = 0;

      if (type === SelectionType.Image) {
        const preview = this.$refs.ipreview as HTMLImageElement;
        width = preview.naturalWidth;
        height = preview.naturalHeight;
      } else {
        const preview = this.$refs.vpreview as HTMLVideoElement;
        width = preview.videoWidth;
        height = preview.videoHeight;
      }

      if (width != this.reqWidth || height != this.reqHeight) {
        this.value = undefined;
        // Create Notification
        var popUp = new PopUpMessage({
          title: "Dimensiones incorrectas",
          message: `El recurso seleccionado no tiene las dimensiones requeridas: ${this.reqWidth}x${this.reqHeight} px.`,
          type: NotificationType.Warning,
        });
        popUp.show();
        this.error = true;
      }
    },
  },
  computed: {
    value: {
      get(): File | undefined {
        return this.modelValue;
      },
      set(value: File | undefined): void {
        this.$emit("update:modelValue", value);
      },
    },
  },
});
</script>

<style scoped>
.select-label {
  font-size: 1.6em;
  font-weight: var(--f-semibold);
  margin-bottom: 0.3em;
  display: block;
}
.ratio-container {
  --aspect-ratio: calc(9 / 32 * 100%);
  cursor: pointer;
}
.ratio-fill {
  background: var(--c-concrete);
}
.image,
.video {
  object-fit: contain;
}
.add-block {
  align-items: center;
  justify-content: center;
  display: flex;
}
.add-cnt {
  text-align: center;
}
.icon {
  height: 40px;
  width: 40px;
}
.select-hint {
  margin: 0;
  color: var(--c-mid-gray);
  font-size: 1.2rem;
  font-weight: var(--f-semibold);
}
</style>
