import { SerieData, SerieDraft } from "@/models/serie";
import { FirebaseUser } from "@/models/users";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import FormData from "form-data";
import { Navigation } from "./common";
import ServiceController from "./service";

export default class SeriesController extends ServiceController {
  static async addSerie(
    serieDraft: SerieDraft,
    user: FirebaseUser
  ): Promise<void> {
    try {
      const token = (await user?.getIdToken()) ?? "";
      const data = new FormData();
      data.append("name", serieDraft.name);
      data.append("description", serieDraft.description);
      data.append("link", serieDraft.link);
      data.append("picture", serieDraft.picture, serieDraft.picture.name);

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": `multipart/form-data; boundary=${data.getBoundary}`,
        },
        onUploadProgress: (progressEvent) => console.log(progressEvent.loaded),
      } as AxiosRequestConfig;
      await axios.post(`${SeriesController.HOST}/series/add`, data, config);
      Navigation.goTo("Series");
    } catch (error) {
      const axiosError = error as AxiosError;
      SeriesController.translateError(axiosError);
    }
  }

  static async updateSerie(
    serieId: string,
    serieDraft: SerieDraft,
    user: FirebaseUser
  ): Promise<void> {
    try {
      const token = (await user?.getIdToken()) ?? "";
      const data = new FormData();
      data.append("name", serieDraft.name);
      data.append("description", serieDraft.description);
      data.append("link", serieDraft.link);
      if (serieDraft.picture) {
        data.append("picture", serieDraft.picture, serieDraft.picture.name);
      }

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": `multipart/form-data; boundary=${data.getBoundary}`,
        },
        params: {
          serieId: serieId,
        },
        onUploadProgress: (progressEvent) => console.log(progressEvent.loaded),
      } as AxiosRequestConfig;
      await axios.put(`${SeriesController.HOST}/series/update`, data, config);
      Navigation.goTo("Series");
    } catch (error) {
      const axiosError = error as AxiosError;
      SeriesController.translateError(axiosError);
    }
  }

  static async deleteSerie(serieId: string, user: FirebaseUser): Promise<void> {
    try {
      const token = (await user?.getIdToken()) ?? "";
      const headers = {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          serieId: serieId,
        },
      } as AxiosRequestConfig;
      await axios.delete(`${SeriesController.HOST}/series/remove`, headers);
      Navigation.goTo("Series");
    } catch (error) {
      const axiosError = error as AxiosError;
      SeriesController.translateError(axiosError);
    }
  }

  static async fecthAll(user: FirebaseUser): Promise<SerieData[] | undefined> {
    try {
      const token = (await user?.getIdToken()) ?? "";
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      } as AxiosRequestConfig;
      const response = await axios.get(
        `${SeriesController.HOST}/series/admin/all`,
        config
      );
      const series: SerieData[] = response.data.series;

      return series;
    } catch (error) {
      const axiosError = error as AxiosError;
      SeriesController.translateError(axiosError);
    }
  }
}
