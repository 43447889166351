
import { defineComponent } from "vue";

import ImageManager from "@/controllers/media-manager";
import PopUpMessage, { NotificationType } from "@/models/popup";
import { SelectionState, SelectionType } from "@/models/common";

export default defineComponent({
  name: "ImageSelect",
  props: {
    modelValue: {
      type: File,
    },
    label: {
      type: String,
      required: true,
    },
    hint: {
      type: String,
      required: true,
    },
    reqHeight: {
      type: Number,
      required: true,
    },
    reqWidth: {
      type: Number,
      required: true,
    },
    prevUrl: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      url: this.prevUrl,
      type: this.prevUrl.includes(".mp4")
        ? SelectionType.Video
        : SelectionType.Image,
      error: false,
    };
  },
  emits: ["update:modelValue"],
  methods: {
    handleResourceAction() {
      const input = this.$refs.file as HTMLInputElement;
      input.click();
    },
    onFileChange(event: Event) {
      this.error = false;
      const input = event.target as HTMLInputElement;
      const state = ImageManager.checkFile(input);
      const selection = ImageManager.getFile(input.files, state);
      if (
        state &&
        state != SelectionState.Image &&
        state != SelectionState.Video
      ) {
        var popUp = new PopUpMessage({
          title: "Error con el archivo",
          message: ImageManager.getMediaErrorMessage(state),
          type: NotificationType.Error,
        });
        popUp.show();
        input.value = input.defaultValue;
        this.error = true;
      }
      if (state === SelectionState.Image) {
        this.type = SelectionType.Image;
      } else {
        this.type = SelectionType.Video;
      }
      this.url = selection.url;
      this.value = selection.file;
    },
    resourceLoaded(type: SelectionType) {
      let height = 0;
      let width = 0;

      if (type === SelectionType.Image) {
        const preview = this.$refs.ipreview as HTMLImageElement;
        width = preview.naturalWidth;
        height = preview.naturalHeight;
      } else {
        const preview = this.$refs.vpreview as HTMLVideoElement;
        width = preview.videoWidth;
        height = preview.videoHeight;
      }

      if (width != this.reqWidth || height != this.reqHeight) {
        this.value = undefined;
        // Create Notification
        var popUp = new PopUpMessage({
          title: "Dimensiones incorrectas",
          message: `El recurso seleccionado no tiene las dimensiones requeridas: ${this.reqWidth}x${this.reqHeight} px.`,
          type: NotificationType.Warning,
        });
        popUp.show();
        this.error = true;
      }
    },
  },
  computed: {
    value: {
      get(): File | undefined {
        return this.modelValue;
      },
      set(value: File | undefined): void {
        this.$emit("update:modelValue", value);
      },
    },
  },
});
