
import { defineComponent, PropType } from "vue";

import BaseCard from "@/components/common/BaseCard.vue";

import { TableData } from "@/models/serie";

export default defineComponent({
  name: "VenueCard",
  components: {
    BaseCard,
  },
  props: {
    data: {
      type: Object as PropType<TableData>,
      required: true,
    },
  },
  data() {
    return {
      menuState: false,
    };
  },
});
