
import { defineComponent } from "vue";

import Form from "@/components/series/SeriesForm.vue";
import SelectImage from "@/components/common/ImageSelect.vue";

import { Navigation, Store } from "@/controllers/common";
import Serie, { SerieDraft } from "@/models/serie";
import Authentication from "@/controllers/auth";

import SeriesController from "@/controllers/series";
import { FirebaseUser } from "@/models/users";
import { useMeta } from "vue-meta";

export default defineComponent({
  name: "Profile",
  setup() {
    useMeta({
      title: "Editar Serie",
      description: "Editar información de serie del pastor.",
    });
  },
  components: {
    Form,
    SelectImage,
  },
  beforeMount() {
    var serie = Store.getValue("edition/serie") as Serie;
    if (serie) {
      this.serieDraft = serie.toDraft();
      this.reference = serie.reference;
      this.prevUrl = serie.picture as string;
      this.edit = true;
    } else {
      Navigation.goTo("AddSerie");
    }
  },
  beforeUnmount() {
    Store.update("edition/storeSerie", undefined);
  },
  data() {
    return {
      loadingState: false,
      edit: false,
      serieDraft: {} as SerieDraft,
      prevUrl: "",
      reference: "",
    };
  },
  computed: {
    titleText(): string {
      return this.edit ? "Editar" : "Agregar";
    },
    currentUser(): FirebaseUser {
      return Authentication.currentUser();
    },
  },
  methods: {
    goBack() {
      Navigation.goBack();
    },
    async addSerie(): Promise<void> {
      this.loadingState = true;
      await SeriesController.addSerie(this.serieDraft, this.currentUser);
      this.loadingState = false;
    },
    async updateSerie(): Promise<void> {
      this.loadingState = true;
      await SeriesController.updateSerie(
        this.reference,
        this.serieDraft,
        this.currentUser
      );
      this.loadingState = false;
    },
    async deleteSerie(): Promise<void> {
      this.loadingState = true;
      await SeriesController.deleteSerie(this.reference, this.currentUser);
      this.loadingState = false;
    },
  },
});
