
import { defineComponent } from "vue";
export default defineComponent({
  name: "BaseCard",
  props: {
    actionable: {
      type: Boolean,
      default: false,
    },
  },
});
