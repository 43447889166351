
import SerieCard from "@/components/series/SerieCard.vue";

import { defineComponent } from "vue";

import BaseField from "@/components/common/BaseField.vue";
import BaseButton from "@/components/common/BaseButton.vue";

import { Navigation, ResponsiveManager, Store } from "@/controllers/common";
import Serie, { SerieData, SeriesColumns, TableData } from "@/models/serie";

import Authentication from "@/controllers/auth";
import SeriesController from "@/controllers/series";
import { FirebaseUser } from "@/models/users";
import { ScreenType } from "@/models/common";
import { useMeta } from "vue-meta";

export default defineComponent({
  name: "Series",
  setup() {
    useMeta({
      title: "Series del Pastor",
      description: "Series del pastor.",
    });
  },
  components: {
    SerieCard,
    BaseField,
    BaseButton,
  },
  created() {
    if (this.currentUser) {
      this.fetchingData = true;
      SeriesController.fecthAll(this.currentUser).then((seriesData) => {
        this.fetchingData = false;
        if (seriesData) {
          const data = seriesData as SerieData[];
          let series: Serie[] = [];
          for (const serie of data) {
            series.push(new Serie(serie));
          }
          this.series = series;
        }
      });
    }
  },
  data() {
    return {
      fetchingData: false,
      filterName: "",
      series: [] as Serie[],
    };
  },
  computed: {
    isMobile(): boolean {
      var screen = ResponsiveManager.retrieveScreenSize();
      return screen === ScreenType.mobile;
    },
    columns(): Array<Record<string, unknown>> {
      return SeriesColumns;
    },
    currentUser(): FirebaseUser {
      return Authentication.currentUser();
    },
    dataSource(): Array<TableData> {
      var key = 0;
      var dataSource: Array<TableData> = [];
      for (const serie of this.series) {
        var serieContent = serie.getTableContent();
        serieContent.key = key;
        dataSource.push(serieContent);
        key = key + 1;
      }
      return dataSource;
    },
    tableContent(): Array<TableData> {
      if (this.filterName === "") {
        return this.dataSource;
      } else {
        var rows: Array<TableData> = [];
        for (const data of this.dataSource) {
          if (
            (data.name as string)
              .toLowerCase()
              .includes(this.filterName.toLowerCase())
          ) {
            rows.push(data);
          }
        }
        return rows;
      }
    },
  },
  methods: {
    addSerie(): void {
      Navigation.goTo("AddSerie");
    },
    editSerie(index: number): void {
      Store.update("edition/storeSerie", this.series[index]);
      Navigation.goTo("EditSerie");
    },
  },
});
