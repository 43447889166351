
import { defineComponent, PropType } from "vue";

import BaseField from "@/components/common/BaseField.vue";
import BaseButton from "@/components/common/BaseButton.vue";

import useVuelidate from "@vuelidate/core";
import { required, url } from "@vuelidate/validators";
import { ResponsiveManager } from "@/controllers/common";
import { ScreenType } from "@/models/common";
import { SerieDraft } from "@/models/serie";

export default defineComponent({
  name: "SerieForm",
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    BaseButton,
    BaseField,
  },
  props: {
    modelValue: {
      type: Object as PropType<SerieDraft>,
      required: true,
    },
    forUpdate: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:modelValue", "addProject", "updateProject"],
  computed: {
    value: {
      get(): SerieDraft {
        return this.modelValue;
      },
      set(value: SerieDraft): void {
        this.$emit("update:modelValue", value);
      },
    },
    respClasses(): Record<string, boolean> {
      var screen = ResponsiveManager.retrieveScreenSize();
      var obj = {
        desktop:
          screen === ScreenType.largeDesktop ||
          screen === ScreenType.smallDesktop,
        tablet: screen === ScreenType.tablet,
        mobile: screen === ScreenType.mobile,
      };
      return obj;
    },
    buttonText(): string {
      return this.forUpdate ? "Actualizar" : "Agregar";
    },
  },
  methods: {
    handleAction(event: Event): void {
      event.preventDefault();
      if (this.forUpdate) {
        this.$emit("updateProject");
      } else {
        this.$emit("addProject");
      }
    },
  },
  validations() {
    const validYoutubeUrl = () =>
      this.value.link.replace("https://youtu.be/", "").length === 11;
    const requiredIfCreatingProject = () =>
      this.forUpdate ? true : this.value.picture != undefined;
    return {
      value: {
        name: { required },
        description: { required },
        link: { required, url, validYoutubeUrl },
        picture: { requiredIfCreatingProject },
      },
    };
  },
});
