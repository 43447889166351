<template>
  <a-spin :spinning="loadingState" :delay="250">
    <div class="edit-header">
      <div class="title">
        <div class="action-button users" @click="goBack">
          <img src="@/assets/actions/back-black.svg" alt="Ir a Inicio" />
        </div>
        <h1 class="global-title">{{ titleText }} Serie</h1>
      </div>
      <a-popconfirm
        placement="leftTop"
        title="¿Está seguro de que desea eliminar este proyecto?"
        ok-text="Sí, eliminar proyecto"
        cancel-text="No, mantener proyecto"
        @confirm="deleteSerie"
        v-if="edit"
      >
        <div class="action-button delete">
          <img src="@/assets/actions/delete-icon.svg" alt="Ir a Inicio" />
        </div>
      </a-popconfirm>
    </div>
    <div class="splitted">
      <Form
        v-model="serieDraft"
        :forUpdate="edit"
        @add-project="addSerie"
        @update-project="updateSerie"
      />
      <SelectImage
        label="Imagen"
        hint="Seleccionar"
        :prevUrl="prevUrl"
        :reqWidth="480"
        :reqHeight="270"
        v-model="serieDraft.picture"
      />
    </div>
  </a-spin>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import Form from "@/components/series/SeriesForm.vue";
import SelectImage from "@/components/common/ImageSelect.vue";

import { Navigation, Store } from "@/controllers/common";
import Serie, { SerieDraft } from "@/models/serie";
import Authentication from "@/controllers/auth";

import SeriesController from "@/controllers/series";
import { FirebaseUser } from "@/models/users";
import { useMeta } from "vue-meta";

export default defineComponent({
  name: "Profile",
  setup() {
    useMeta({
      title: "Editar Serie",
      description: "Editar información de serie del pastor.",
    });
  },
  components: {
    Form,
    SelectImage,
  },
  beforeMount() {
    var serie = Store.getValue("edition/serie") as Serie;
    if (serie) {
      this.serieDraft = serie.toDraft();
      this.reference = serie.reference;
      this.prevUrl = serie.picture as string;
      this.edit = true;
    } else {
      Navigation.goTo("AddSerie");
    }
  },
  beforeUnmount() {
    Store.update("edition/storeSerie", undefined);
  },
  data() {
    return {
      loadingState: false,
      edit: false,
      serieDraft: {} as SerieDraft,
      prevUrl: "",
      reference: "",
    };
  },
  computed: {
    titleText(): string {
      return this.edit ? "Editar" : "Agregar";
    },
    currentUser(): FirebaseUser {
      return Authentication.currentUser();
    },
  },
  methods: {
    goBack() {
      Navigation.goBack();
    },
    async addSerie(): Promise<void> {
      this.loadingState = true;
      await SeriesController.addSerie(this.serieDraft, this.currentUser);
      this.loadingState = false;
    },
    async updateSerie(): Promise<void> {
      this.loadingState = true;
      await SeriesController.updateSerie(
        this.reference,
        this.serieDraft,
        this.currentUser
      );
      this.loadingState = false;
    },
    async deleteSerie(): Promise<void> {
      this.loadingState = true;
      await SeriesController.deleteSerie(this.reference, this.currentUser);
      this.loadingState = false;
    },
  },
});
</script>

<style scoped>
.verse-preview {
  color: black;
  font-size: 1.5em;
  font-family: "Roboto", sans-serif;
  margin: 0 10%;
  align-items: center;
  display: flex;
}

@media only screen and (min-width: 768px) {
  .edit-header {
    width: calc(50% - 2rem);
  }
}
</style>
